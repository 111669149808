import { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";


import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";
import DashboardHeader from "../../components/DashboardHeader";


//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

//TABS - ANT DESIGN
// import { Tabs } from "antd";

import TransactionList from "../accounting/TransactionList";
import CustomerEdit from "./CustomerEdit";
import Loading from "../../components/Loading";

import UserAlerts from "../../components/UserAlerts";


const Customer = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [error, setError] = useState('');
    const errorRef = useRef(null);

    const [removeBorder, setRemoveBorder] = useState(true);

    // const { loading, data, fetchData}  = useAxios();   
    const { loading}  = useAxios();   
    const { data:transactionData, fetchData:fetchTransactionData}  = useAxios();   
    const { data:customerData, fetchData:fetchCustomerData}  = useAxios();   
    const { data:countryData, fetchData:fetchCountryData}  = useAxios();   
    const { data:contactData, fetchData:fetchContactData}  = useAxios();  
    
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();


    // FETCH CLIENT
    // const { data: clientProfile } = useAxios({
    //     url: `/clientprofiles/${localStorage.getItem('clientID')}`,
    //     method: 'get',
    // })  

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    const { data: transactions } = useQuery({
        queryKey: ['customer-statement', page],
        queryFn: async () => {
          const response = await authAxios.get(`/accounting/customer-ledger/?customer=${params.id}&page=${page}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
    });

    const { data: customer } = useQuery({
        queryKey: ['customer'],
        queryFn: async () => {
          const response = await authAxios.get(`crm/customers/${params.id}`);
          return response.data
        },
    });

    const { data: countries } = useQuery({
        queryKey: ['countries'],
        queryFn: async () => {
          const response = await authAxios.get('/countries');
          return response.data
        },
    });

    // const { data: contacts } = useQuery({
    //     queryKey: ['contacts'],
    //     queryFn: async () => {
    //       const response = await authAxios.get('/contacts');
    //       setContact(response.data.find(contact => contact.id === customer.contact))
    //       return response.data
    //     },
    // });

    //FETCH COUNTRIES
    // const [countries, setCountries] = useState([]);
    // const fetchCountries = () => {
    //     fetchCountryData({
    //         url: '/countries',
    //         method: 'get',
    //     })
    // }

    //FETCH CUSTOMER
    // const [customer, setCustomer] = useState("");
    // const fetchCustomer = () => {
    //     fetchCustomerData({
    //         url: `crm/customers/${params.id}`,
    //         method: 'get',
    //     })
    // }

    //FETCH CONTACT(S)
    // const [contact, setContact] = useState("");
    // const [contacts, setContacts] = useState([]);
    // const fetchContacts = () => {
    //     fetchContactData({
    //         // url: `crm/customers/${params.id}`,
    //         url: '/contacts',
    //         method: 'get',
    //     })
    // }

    // FETCH TRANSACTIONS
    // const [transactions, setTransactions] = useState([]);
    // const fetchTransactions = () => {
    //     fetchTransactionData({
    //         url: `/accounting/customer-ledger/?customer=${params.id}&page=${page}`,
    //         method: 'get',
    //     })
    // }


    //CALC STATS
    let sumInvoices = 0
    let sumReceipts = 0
    let balance = 0
        
    const calcStats = () => {
        try {
            for(let i= 0; i < transactions.length; i++){
                if (transactions[i].type==="010"){
                    sumInvoices = sumInvoices +  parseFloat(transactions[i].amount)
                }
                if (transactions[i].type==="020"){
                    sumReceipts = sumReceipts +  parseFloat(transactions[i].amount)
                }
            }
            balance = sumInvoices - sumReceipts
        } catch (error) {
            console.log(error);
        }
    }

    calcStats()

    //Bootstrap Tabs
    const [key, setKey] = useState('profile');
    const handleTabChange = (k) => {
        setKey(k)
    };

    // PAGINATION
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])

    return (
        <div className="dashboard-main">
            {customer &&

            <section className="dashboard-content dashboard-content-compact">
                 <div ref={errorRef}>
                    <UserAlerts error={error} />
                </div>

                <DashboardHeader dashboardTitle={customer.name} dashboardPreTitle="Customer" removeBorder={removeBorder} />

              
                <Tabs
                    activeKey={key}
                    onSelect={(k) => handleTabChange(k)}
                    className="dashboard-tabs"
                    id="customer-tabs"
                    mountOnEnter={true}
                    unmountOnExit={true}
                    transition={false}
                    >
                    
                    <Tab eventKey="profile" title="Profile">
                        <CustomerEdit 
                            customer={customer}
                            setError={setError}
                            // contact={contact}
                            // countries={countries}
                        />
                    </Tab>

                    <Tab eventKey="statement" title="Statement">
                        {clientProfile &&
                        <div className="dashboard-content-subheader">
                            <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Invoices
                                </div>
                                <div className="dashboard-card-text">
                                    {clientProfile.currency_code} {sumInvoices.toLocaleString()}

                                </div>
                            </div>
                            <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Receipts
                                </div>
                                <div className="dashboard-card-text">
                                    {clientProfile.currency_code} {sumReceipts.toLocaleString()}

                                </div>
                            </div>
                            <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Balance
                                </div>
                                <div className="dashboard-card-text">
                                    {clientProfile.currency_code} {balance.toLocaleString()}
                                </div>
                            </div>

                        </div>
                        }
                        <TransactionList 
                            module="CRM" 
                            transactions={transactions} 
                            // fetchTransactions={fetchTransactions} 
                            handlePageChange={handlePageChange} 
                            page={page} 
                            pagecount={pagecount}
                        />
                    </Tab>
                </Tabs> 

            </section>
            }

        </div>
    );
  };
  
  export default Customer;