import React from "react";
import axios from "axios";
import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";
import useAxiosInterceptor from '../../components/useAxiosInterceptor';

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InputLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { MenuItem } from "@mui/material";
import { Radio } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";
import { Select } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Autocomplete } from "@mui/material";


import moment from 'moment';

import Sidebar from "../../components/Sidebar";
import UserAlerts from "../../components/UserAlerts";
// import LayoutForm from "./LayoutForm";
import Layout from "../../components/Layout";
import DashboardHeader from "../../components/DashboardHeader";
// import CustomSnackBar from "../components/CustomSnackBar";
import CustomSnackBar from "../../components/CustomSnackBar";

// import Snackbar from '@mui/material/Snackbar';
// import MuiAlert from '@mui/material/Alert';


const ClientProfile = () => {
    // const Alert = React.forwardRef(function Alert(props, ref) {
    //     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    //   });
    //   const [open, setOpen] = React.useState(false);

    // const handleClick = () => {
    //     setOpen(true);
    // };

    // const handleClose = (event, reason) => {
    //     if (reason === 'clickaway') {
    //     return;
    //     }

    //     setOpen(false);
    // };

    const params = useParams()
    const [isUpdated, setIsUpdated] = useState(false)

    const {fetchData, data, response, error}  = useAxios();


    const [dt, setDt] = useState(new Date());

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    // const [mobile, setMobile] = useState('');
    const [phone, setPhone] = useState('');

    const [address, setAddress] = useState('');
    const [currency, setCurrency] = useState('');
    const [country, setCountry] = useState('');
    const [website, setWebsite] = useState('');
    const [debtorsControl, setDebtorsControl] = useState('');
    const [apAccount, setApAccount] = useState('');
    const [contact, setContact] = useState("");

    const client = localStorage.getItem('clientID');
    const user = localStorage.getItem('userID');


    const [nameError, setNameError] = useState(false)
    // const [mobileError, setMobileError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)

    const [emailError, setEmailError] = useState(false)
    // const [idNumberError, setIdNumberError] = useState(false)

    // FETCH CLIENT PROFILE
    const clientID = localStorage.getItem('clientID');
    const clientProfileID = localStorage.getItem('clientProfile');

    const [clientProfile, setClientProfile] = useState([]);
    const { data: clientProfileData } = useAxios({
        url: `/clientprofiles/${clientProfileID}`,
        method: 'get'
    })  

    const { data: countries } = useAxios({
        url: '/countries',
        method: 'get'
    })

    const { data: contacts } = useAxios({
        url: '/contacts',
        method: 'get'
    })

    const navigate = useNavigate();


    const handleChange = (event) => {
        setName(event.target.value);
      };

    // const handleIdNumber = (e) => {
    //     if (e.target.value.length>9){
    //         setIdNumberError(true)
    //     }
    //     else{
    //         setIdNumberError(false)
    //         setId_number(e.target.value)
    //     }
    // }

    const getDefaultValues = () => {
        const country1 = countries.find(country => country.id === clientProfile.country)
        setCountry(countries.indexOf(country1))
        setName(clientProfile.name)
        setEmail(clientProfile.email) 
        setPhone(clientProfile.telephone) 
        setAddress(clientProfile.address) 
        setWebsite(clientProfile.website) 
        setContact(clientProfile.contact) 

        setCurrency(clientProfile.currency) 
        setDebtorsControl(clientProfile.debtors_control) 
        setApAccount(clientProfile.ap_account) 
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setNameError(true)
        }

        if (phone === '' ) {
            setPhoneError(true)
        }

        if (email === '' ) {
            setEmailError(true)
        }

        
        // if (name && mobile && email && idNumberError === false ) {

        if (name && phone && email) {
            const telephone = phone.replace(/[()" "]/g,"")

            //Change the format of birth date before saving
            const birth_date = moment(dt).format("YYYY-MM-DD")

            const debtors_control = debtorsControl
            const ap_account = apAccount

            const cp = { client, name, address, country, email, telephone, website, birth_date, user, currency, debtors_control, ap_account, contact };
            console.log(cp)

            fetchData({
                // url: `/clientprofile/${params.id}/`,
                url: `/clientprofiles/${clientProfileID}/`,
                method: 'put',
                data: cp
            })
            // .then(function (res) {
            .then(()=>{                    
                if (response.status === 200){
                    setSuccess(true)
                    setSuccessMessage("Your profile has been updated")
                    navigate('/dashboard/settings')
                }
            })
        } 
    }

    //Handle snackbar for success feedback to the user
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleSnackBarClose = () => {
        // console.log("DRAWER CLOSE")
        setSuccess(false)
    };


    useEffect(() => {
        // if (response.status === 200){
        //     navigate('/customers/');
        // }    
        if (clientProfileData){
            setClientProfile(clientProfileData)
            setContact(contacts.find(contact => contact.id === clientProfileData.contact))
        }
    }, [clientProfileData]); 


    useEffect(() => {
        if (setClientProfile && contact){
            getDefaultValues()
            // if (customer.type==="10"){
            //     setIsPerson(true)
            // }
        }
    }, [setClientProfile, contact]); 

    // console.log("COUNTRIES", countries)

    return(
    // <Layout>

        <section className="dashboard-form">
            {contact &&
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <div className="grouped-fields-light">
                    <TextField className="custom-input"
                        value={name}
                        // value={contact.name}
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)
                            
                        }}
                        // onChange={handleChange}
                        label="Name" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        required
                        error={nameError}
                    />
                    <TextField className="custom-input"
                        value={email ?? ''}
                        // value={contact.email}
                        onChange={(e) => {
                            setEmailError(false)
                            setEmail(e.target.value)
                        }}
                        label="Email" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        required
                        error={emailError}
                    />

                    <NumberFormat 
                        value = {phone}
                        // value = {contact.telephone}

                        onChange={(e) => {
                            setPhoneError(false)
                            setPhone(e.target.value)
                        }}
                        className="custom-input"
                        fullWidth
                        label="Telephone" 
                        variant="outlined" 
                        customInput={TextField}
                        format="### (###) ######" mask="_"
                        required
                        error={phoneError}
                    />

                    {contact &&
                    <TextField className="custom-input"
                        value={address ?? ''}
                        // value={contact.address}
                        onChange={(e) => setAddress(e.target.value)}
                        label="Address" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={mobileError}
                    />
                    }

                    {country &&
                    <Autocomplete
                        // value={contact.country_name}
                        value={ countries[country]}

                        onChange={(event, newValue) => {
                            setCountry(newValue.id);
                            console.log("VALUE", newValue.id)
                            }}
                        options={countries}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Country"  /> }
                    />
                    }

                    <TextField className="custom-input"
                        // value={contact.website}
                        value={website ?? ''}

                        onChange={(e) => setWebsite(e.target.value)}
                        label="Website" 
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={mobileError}
                    />  
                </div>

                <div className="button-border-top">
                    <button id="submit" className="btn btn-primary form-buttons" type="submit">
                        Save
                    </button>
                </div>
            </form>
            }
            
            <CustomSnackBar success={success} successMessage={successMessage} handleSnackBarClose={handleSnackBarClose}/>

        </section>

        // </Layout>

    )

}

export default ClientProfile;

