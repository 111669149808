import React, { useEffect, useRef } from "react"
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import authAxios from "./authAxios";
import DashboardHeader from "./DashboardHeader";
// import { useMediaQuery } from 'react-responsive'

//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import TransactionList from "../features/accounting/TransactionList";
import SupplierEdit from "./SupplierEdit";

const Supplier = () => {
    const params = useParams()
    const supplierId = params.id

    const [error, setError] = useState('');

    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    const sumBills = useRef(0)
    const sumPayments = useRef(0)
    const balance = useRef(0)

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    const { data: supplier } = useQuery({
        queryKey: ['supplier', supplierId],
        queryFn: async () => {
          const response = await authAxios.get(`/purchase/suppliers/${supplierId}`);
          return response.data
        },
    });

    const { data: transactions } = useQuery({
        queryKey: ['customer-statement', supplierId, page],
        queryFn: async () => {
          const response = await authAxios.get(`/accounting/supplier-ledger/?supplier=${supplierId}&page=${page}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
    });

    //Bootstrap Tabs
    const [key, setKey] = useState('profile');
    const [removeBorder, setRemoveBorder] = useState(true);

    // const activeTabStatement = () => {
    //     setKey('statement')
    // }

    // PAGINATION
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    useEffect(()=>{
        if (transactions){
            try {
                for(let i= 0; i < transactions.length; i++){
                    if (transactions[i].type==="100"){
                        sumBills.current = sumBills.current +  parseFloat(transactions[i].amount)
                    }
                    if (transactions[i].type==="110"){
                        sumPayments.current = sumPayments.current +  parseFloat(transactions[i].amount)
                    }
                }
                balance.current = sumBills.current - sumPayments.current
            } catch (error) {
                console.error(error);
            }
        }
    },[transactions])

 
    return (
            <div className="dashboard-main">
                {supplier &&

                <section className="dashboard-content">

                    <DashboardHeader dashboardTitle={supplier.name} dashboardPreTitle="Suppliers" removeBorder={removeBorder} />

                    <Tabs
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="dashboard-tabs"
                        >
                        <Tab eventKey="profile" title="Profile">
                            <SupplierEdit 
                                supplier={supplier}
                                setError={setError}
                            />
                        </Tab>

                        <Tab eventKey="statement" title="Statement">
                            {clientProfile &&
                            <div className="dashboard-content-subheader">
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Bills
                                    </div>
                                    <div className="dashboard-card-text">
                                        {/* KES {sumBills.toLocaleString()} */}
                                        {clientProfile.currency_code} {sumBills.current.toLocaleString()}

                                    </div>
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Payments
                                    </div>
                                    <div className="dashboard-card-text">
                                        {/* KES {sumPayments.toLocaleString()} */}
                                        {clientProfile.currency_code} {sumPayments.current.toLocaleString()}

                                    </div>
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Balance
                                    </div>
                                    <div className="dashboard-card-text">
                                        {/* KES {balance.toLocaleString()} */}
                                        {clientProfile.currency_code} {balance.current.toLocaleString()}

                                    </div>
                                </div>

                            </div>
                            }
                            {/* {client && */}
                            <TransactionList 
                                module="SCM" 
                                transactions={transactions} 
                                // fetchTransactions={fetchTransactions} 
                                handlePageChange={handlePageChange} 
                                page={page} 
                                pagecount={pagecount}
                                // client={client}
                            />
                            {/* } */}
                        </Tab>
                    </Tabs>     

                </section>
                }

            </div>
    );
  };
  
  export default Supplier;