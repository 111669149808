import React from "react"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

// import logo from '../assets/stella-logo.svg'
// import logo from '../assets/gesti-logo.svg'
// import logo from '../assets/zidi-logo.svg'
import logo from '../assets/zidi-logo.svg'


function Header() {
    return (
        <header>
            <nav className="navbar">
                {/* <div className="nav-brand"> */}
                <div className="navbar-brand">
                    <Link to="/">
                        <img src={logo} alt="brand logo"/>  
                    </Link>
                </div>

                <div className="navbar-icon">
                    <Link to ="/dashboard/stats">
                        <FontAwesomeIcon icon={faBars} className="nav-icon"/> 
                    </Link> 
                </div>

            </nav>

            
        </header>
    ) 
}

export default Header