import React, { useEffect } from "react"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import useAxios from "../../components/useAxios";
import useAxiosInterceptor from "../../components/useAxiosInterceptor";

import Layout from "../../components/Layout";
import DashboardHeader from "../../components/DashboardHeader";

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
// import { Card, CardContent } from "@mui/material";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive'


//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ClientProfile from "./ClientProfile";
import AccountingSettings from "./AccountingSettings";
import EbaySettings from "./EbaySettings";


const AppSettings = () => {
    //Bootstrap Tabs
    const [key, setKey] = useState('company');
    // const [activeKey, setActiveKey] = useState('profile');

    const [removeBorder, setRemoveBorder] = useState(true);

    const clientID = localStorage.getItem('clientID');

    //  Fetch Client
    // const { data: clientprofiles } = useAxios({
    //     url: "/clientprofiles",
    //     method: 'get'
    // })  
    // const client = clientprofiles.find(function(clientprofile) {
    //     return (clientprofile.client === parseInt(clientID));
    // });
    const { data: client } = useAxios({
        url: `/clientprofiles/${clientID}`,
        method: 'get'
    })  


    return(

        <div className="dashboard-main">

            <section className="dashboard-content">

                <DashboardHeader dashboardTitle="Settings" dashboardPreTitle="Settings" removeBorder={removeBorder}  />

                {client && 

                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="dashboard-tabs"
                >
                    <Tab eventKey="company" title="Company">
                        <ClientProfile  />

                    </Tab>

                    <Tab eventKey="acc" title="Accounting">
                        <AccountingSettings clientProfile={client} />
                    </Tab>
                    {client.ebay_is_active &&
                    <Tab eventKey="ebay" title="EBay">
                        <EbaySettings clientProfile={client} />
                    </Tab>
                    }
                    
                    
                </Tabs>     
                }

            </section>
            

        </div>

    )

}

export default AppSettings;