// import React from "react"

import { useEffect, useState, useRef, useContext } from "react";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { useNavigate } from 'react-router-dom';

// import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";

import { InputLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { TextField, Autocomplete } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";

import UserAlerts from "../../components/UserAlerts";

import { Modal } from 'antd';
import CustomSwitch from "../../components/CustomSwitch";
import { UserContext } from "../../App";

// const AccountNew = ({isDrawerOpen, handleDrawerClose}) => {




const CollectionNew = ({isModalOpen, setIsModalOpen}) => {
    const page_size = useContext(UserContext);
    const errorRef = useRef(null);

    const queryClient = useQueryClient();
    const [page, setPage] = useState(1);


    //Axios Post Custom Hook
    // const {error, response, fetchData}  = useAxios();
    const [checked, setChecked] = useState(false);

    //  Fetch Account Types
    // const { data: acctypes } = useAxios({
    //     url: '/accounting/account-types',
    //     method: 'get'
    // })

    // const fetchAccountTypes = async () => {
    //     const response = await authAxios.get('/accounting/account-types')
    //     return response.data
    // }


    // const fetchAccounts = async () => {
    //     const response = await authAxios.get("/accounting/accounts-parent")
    //     return response.data
    // }

    // const [accounts, setAccounts] = useState([])
    // const { data: accountsData } = useAxios({
    //     url: `/accounting/accounts/?page_size=${page_size}`,
    //     // url: "/inventory/products",
    //     method: 'get'
    // })

    // const accounts = useQuery(['accounts'], async () => {
    //     const response = await authAxios.get(`/accounting/accounts/?page_size=${page_size}`);
    //     // console.log("RES",response)
    //     return await response.data.results;
    //   });
    

    // const {data:accountTypes} = useQuery({
    //     queryKey: ['accountTypes'], 
    //     queryFn: ()=>fetchAccountTypes(),
    // })

    // const {data:accounts} = useQuery({
    //     queryKey: ['accounts-parent'], 
    //     queryFn: ()=>fetchAccounts(),
    // })

    // useEffect(() => {
    //     if (response.status === 201){
    //         queryClient.invalidateQueries(['accounts', page]);
    //         setIsModalOpen(false);
    //     }   
    //     if (error) {
    //         errorRef.current.scrollIntoView();              
    //     } 
    // }, [response.status, error]); 
    
    // const [type, setType] = useState('');
    // const [parent, setParent] = useState('');
    const [name, setName] = useState('');
    const [memo, setMemo] = useState('');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');
    const [typeError, setTypeError] = useState(false)
    const [nameError, setNameError] = useState(false)

    const navigate = useNavigate();

    const handleCancel = () => {
        setIsModalOpen(false);
        };


    // const [createAccount, { isLoading, error, success }] = useMutation(
    //     newAccount,
    // )
  
    const mutation = useMutation({
        mutationFn: (collection) => {
        //   return fetch('/api', formData)
            // return authAxios.post("/accounting/accounts/", account);
            return authAxios.post("/products/collections/", collection);

        },
        onSuccess: (result) => {
            // queryClient.invalidateQueries(['accounts', page]);
            // console.log("RESULTS",result)
            queryClient.invalidateQueries(['collections', page]);
            // queryClient.setQueryData(['accounts',page], [result.data]);
            setIsModalOpen(false);
        },
      })

    const handleSubmit = (e) => {
     
        e.preventDefault();

        setNameError(false)
        // setTypeError(false)

        if (name === '') {
            setNameError(true)
        }

        // if (type === '' ) {
        //     setTypeError(true)
        // }

        if (name ) {
            const collection = { client, name, memo, user };
            // createAccount(account);
            mutation.mutate(collection)
        } 
    }

    return (
        <>
        <Modal className="dashboard-modal" title="Add Collection" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>

            <section className="dashboard-form">
                {/* <div ref={errorRef}>
                    <UserAlerts error={error}  />
                </div> */}

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                    {/* <FormControl 
                        variant="outlined" 
                        fullWidth className="custom-input" 
                        error={typeError}
                        required
                    >
                        <InputLabel>Type</InputLabel>
                        <Select
                            className="custom-select"
                            label="Type"
                            onChange={(e) => {
                                setTypeError(false)
                                setType(e.target.value)}
                            }
                            defaultValue = ""
                        >
                            {accountTypes && accountTypes.map((accountType) => { 
                                return(
                                <MenuItem key={accountType.code} value={accountType.id}>{accountType.name}</MenuItem>
                                )
                            })}   
                            
                        </Select>
                    </FormControl> */}


                    <TextField className="custom-input"
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)}
                        }
                        label="Name" 
                        variant="outlined" 
                        // color="secondary" 
                        fullWidth
                        required
                        error={nameError}
                    />
                    {/* <div className="custom-switch-container">
                        <CustomSwitch checked={checked} setChecked={setChecked}/>
                        <div className="custom-switch-label">
                            Is this a sub account?
                        </div>
                    </div> */}

                    {/* {checked &&

                    <Autocomplete
                        // onChange={(e, newValue) => updateAccount(newValue)}  
                        onChange={(event, newValue)=>setParent(newValue.id)}
                        options={accounts}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" {...params} label="Parent Account" /> }
                    />
                    } */}

                    <TextField className="custom-input"
                        onChange={(e) => setMemo(e.target.value)}
                        label="Memo"
                        variant="outlined"
                        multiline
                        rows={3}
                        fullWidth
                        // error={messageError}
                    />

                </form>
            
            
            </section>
        </Modal>
    </>


    ) 
}

export default CollectionNew;
