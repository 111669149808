import React, { useEffect, useCallback } from "react"
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useAxios from "../../components/useAxios";

import authAxios from "../../components/authAxios";
import { useQuery } from "@tanstack/react-query";


// import { Pagination } from "@mui/material";

import DashboardHeader from "../../components/DashboardHeader";
import TransactionList from "../accounting/TransactionList";
import AccountEdit from "../accounting/AccountEdit";

//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import ProductEdit from "./ProductEdit";
import UserAlerts from "../../components/UserAlerts";



const Product = () => {
    const params = useParams()
    const [error, setError] = useState('');
    const errorRef = useRef(null);

    const navigate = useNavigate()

    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    const [transactions, setTransactions] = useState([]);

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    const { data: product } = useQuery({
        queryKey: ['product'],
        queryFn: async () => {
          const response = await authAxios.get(`products/skus/${params.id}`);
          return response.data
        },
    });

    let sumDebits = 0
    let sumCredits = 0
    let balance = 0
        
    const calcStats = () => {
        try {

            for(let i= 0; i < transactions.length; i++){
                if (transactions[i].entry_type==="D"){
                    sumDebits = sumDebits +  parseFloat(transactions[i].amount)
                }
                if (transactions[i].entry_type==="C"){
                    sumCredits = sumCredits +  parseFloat(transactions[i].amount)
                }
            }
            balance = sumDebits - sumCredits
        } catch (error) {
            console.log(error);
        }

    }
    calcStats()

    //Bootstrap Tabs
    const [key, setKey] = useState('profile');
    const [removeBorder, setRemoveBorder] = useState(true);


    // PAGINATION
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])


    return (
            <div className="dashboard-main">
                {product &&
                <section className="dashboard-content">
                    <div ref={errorRef}>
                        <UserAlerts error={error} />
                    </div>

                    <DashboardHeader dashboardTitle={product.name} dashboardPreTitle="Product" removeBorder={removeBorder} />
                    <Tabs
                        // id="dashboard-form-tabs"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="dashboard-tabs"
                        >
                        <Tab eventKey="profile" title="Profile">
                            <ProductEdit product={product} setError={setError}/>
                        </Tab>

                        <Tab eventKey="ledger" title="Orders" disabled>
                            {clientProfile &&
                            <div className="dashboard-content-subheader">
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Debits
                                    </div>
                                    <div className="dashboard-card-text">
                                        {clientProfile.currency_code} {sumDebits.toLocaleString()}

                                    </div>
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Credits
                                    </div>
                                    <div className="dashboard-card-text">
                                        {clientProfile.currency_code} {sumCredits.toLocaleString()}
                                    </div>
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Balance
                                    </div>
                                    <div className="dashboard-card-text">
                                        {clientProfile.currency_code} {balance.toLocaleString()}
                                    </div>
                                </div>

                            </div>
                            }
                            
                            {/* <TransactionList 
                                module="COA" 
                                transactions={transactions} 
                                fetchTransactions={fetchTransactions} 
                                handlePageChange={handlePageChange} 
                                page={page} 
                                pagecount={pagecount}
                            /> */}
                            
                        </Tab>
                    </Tabs>     

                </section>
                }

            </div>

    );
  };
  
  export default Product;