
import { useLocation, useNavigate } from 'react-router-dom';
import useAxios from "./useAxios";

// import { Button, Result, Space } from 'antd';
// import { FilePdfOutlined } from '@ant-design/icons';

import { Result } from 'antd';
import {IconButton, Button} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import Layout from './Layout';
import InvoiceReport from '../reports/InvoiceReport';


const Confirmed = () => {

    //Pass state variables from router
    const location = useLocation();
    const navigate = useNavigate();
    const {fetchData}  = useAxios();   


    const title = location.state.title;
    const subTitle = location.state.subtitle;
    const reference = location.state.reference;
    // const id = location.state.id;



    // console.log("LOCATION",location)

    const printInvoice = () => {
        navigate('/dashboard/invoicereport/',{state:{reference:reference}});
        // navigate('/dashboard/invoicereport/',{state:{id:id}});


        // <InvoiceReport />

        // fetchData({
        //     url: '/accounting/invoice/',
        //     method: 'get',
        //     responseType: 'blob'
        //   })

    }


    return (
        // <Layout>
            <div className="dashboard-main">

                    <Result
                        status="success"
                        // title="Successfully Purchased Cloud Server ECS!"
                        title={title}
                        // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
                        subTitle={subTitle}
                        extra={[
                                // <Button type="primary" key="console">
                                //     Go Console
                                // </Button>,
                                // <Button key="Print">Print Invoice</Button>,
                                <Button
                                    variant="outlined" 
                                    size="small"
                                    onClick={printInvoice}
                                    startIcon={<DownloadForOfflineIcon />}
                                >
                                    Download
                                </Button>,
                                <Button
                                 variant="outlined" 
                                 size="small"
                                 startIcon={<MailOutlineIcon />}
                                >
                                    Email
                                </Button>,
                                <Button
                                variant="outlined" 
                                size="small"
                                startIcon={<WhatsAppIcon />}
                                >
                                   WhatsApp
                                </Button>
                               

                        ]}
                    />

            </div>
        // </Layout>

    )
}

export default Confirmed;