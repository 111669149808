import React, { useEffect } from "react"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import axios from "axios";

import useAxios from "./useAxios";
import useAxiosInterceptor from "./useAxiosInterceptor";

import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";
// import Pagination from './Pagination';

import { useMediaQuery } from 'react-responsive'


//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import Bookings from "./Bookings";
// import BookingList from "./BookingList";
// import TransactionList from "../features/accounting/TransactionList";
// import TransactionNew from "./TransactionNew";
import DealList from "./DealList";


// import Sonnet from '../../components/Sonnet';

// const Transactions = ({ txns, loading }) => {
const Lead = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const params = useParams()
    const navigate = useNavigate()

    const {error, response, data, fetchData}  = useAxios();   


    const handleDrawerOpen = () => {
        // console.log("DRAWER CLOSE")
        setIsDrawerOpen(true)
    };

    const handleDrawerClose = () => {
        console.log("DRAWER CLOSE")
        setIsDrawerOpen(false)
    };

    //Axios
    // const { data: txns, loading } = useAxios('/statement')
    // const { data: txns, loading } = useAxios(`/statement/?customer=${params.id}`)
    // const {response, error } = useAxiosInterceptor();


    // FETCH CLIENT PROFILE
    const clientID = localStorage.getItem('clientID');
    const clientProfileID = localStorage.getItem('clientProfile');

    const { data: clientprofile } = useAxios({
        url: `/clientprofiles/${clientProfileID}`,
        method: 'get'
    })  


    // FETCH CUSTOMER
    const { data: customer } = useAxios({
        url: `/customers/${params.id}`,
        method: 'get'
    })  

    
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    // FETCH DEALS
    const [deals, setDeals] = useState([]);
    async function fetchDeals () {
        try {

            fetchData({
                url: `/deals/?page=${page}`,
                method: 'get'
            })
            .then(()=>{
                // console.log("DATA",data)
                // console.log("RESPONSE",response)
                setDeals(data.results);
                setPageCount(data.page_count)
            })

        } catch (error) {
            console.log(error);
        }
    }


    // const customerDeals = deals.filter(function(deal) {
    //     return deal.customer === parseInt(customer.id);
    // });

    // const customerDeals = deals.filter(function(deal) {
    //     return deal.customer === parseInt(customer.id);
    // });

    //FETCH TRANSACTION TYPES 
    const { data: ttypes } = useAxios({
        url: '/transtypes',
        method: 'get'
    })
    
    
    const invoice = ttypes.find(function(ttype) {
        return (ttype.code === "10");
    });


    const receipt = ttypes.find(function(ttype) {
        return (ttype.code === "20");
    });


    const handleFabClick = () => {
        navigate('/ratenew/',{state:{property:params.id}})
    }
    

    // const [sumInvoices, setSumInvoices] = useState(0)
    let sumInvoices = 0
    let sumReceipts = 0
    let balance = 0
    let cntBookings = 0
    let sumBookings = 0
    // let balance = 0
    // let customer = ""
        
    const calcStats = () => {
        // const [sumInvoices, setSumInvoices] = useState(0)
        try {
            

            // for(let i= 0; i < transactions.length; i++){
            //     if (transactions[i].type===invoice.id){
            //         sumInvoices = sumInvoices +  parseFloat(transactions[i].amount)
            //     }
            //     if (transactions[i].type===receipt.id){
            //         sumReceipts = sumReceipts +  parseFloat(transactions[i].amount)
            //     }
            
            // }
            balance = sumInvoices - sumReceipts

            //Calculate Booking Stats
            // if (customerDeals){
            //     for(let i= 0; i < customerDeals.length; i++){
            //         cntBookings = cntBookings + 1
            //         sumBookings = sumBookings + parseFloat(customerDeals[i].price)
            //     }
            // } 


        } catch (error) {
            // console.error(error);
            console.log(error);

        }

    }
    calcStats()

    //Bootstrap Tabs
    const [key, setKey] = useState('profile');
    // const [activeKey, setActiveKey] = useState('profile');

    const [removeBorder, setRemoveBorder] = useState(true);

    const activeTabStatement = () => {
        setKey('statement')
    }

    // console.log("KEY", key)

    // PAGINATION
    const handlePageChange = (event, value) => {
        setPage(value);
        console.log("PAGE", page)
        // fetchTransactions()
    };


    useEffect(()=>{
        fetchDeals()
       
    },[isDrawerOpen, key, page, data.count])


    return (
        // <Layout>
            <div className="dashboard-main">

                <section className="dashboard-content">

                    <DashboardHeader dashboardTitle={customer.name} dashboardPreTitle="Leads" removeBorder={removeBorder} />

                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="dashboard-tabs"
                        >
                        <Tab eventKey="profile" title="Profile">
                            
                            <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Address
                                </div>
                                <div>
                                    {customer.address}
                                </div>
                            </div>
                            <div className="dashboard-content-subheader">
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Number of Bookings
                                    </div>
                                    <div className="dashboard-card-text">
                                        {cntBookings}
                                    </div>
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Occupancy
                                    </div>
                                    <div className="dashboard-card-text">
                                        0%
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        
                        <Tab eventKey="deals" title="Enquiries/Deals">
                            {/* <BookingList  bookings={customerBookings} property={unit.property}/> */}
                            <div className="dashboard-content-subheader">
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Number of Bookings
                                    </div>
                                    <div className="dashboard-card-text">
                                        {cntBookings}
                                    </div>
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Value of Bookings
                                    </div>
                                    <div className="dashboard-card-text">
                                        KES {sumBookings.toLocaleString()}
                                    </div>
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Average Length of Stay (Days)
                                    </div>
                                    <div className="dashboard-card-text">
                                        {/* {sumReceipts} */}
                                        0
                                    </div>
                                </div>

                            </div>
                            {/* <BookingList  bookings={customerDeals} /> */}
                            <DealList 
                                module="CRM" 
                                deals={deals} 
                                fetchDeals={fetchDeals} 
                                handlePageChange={handlePageChange} 
                                page={page} 
                                pagecount={pagecount}
                            />

                        </Tab>
                        <Tab eventKey="activities" title="Activities">
                            <div className="dashboard-content-subheader">
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Invoices
                                    </div>
                                    <div className="dashboard-card-text">
                                        KES {sumInvoices.toLocaleString()}
                                    </div>
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Receipts
                                    </div>
                                    <div className="dashboard-card-text">
                                        KES {sumReceipts.toLocaleString()}
                                    </div>
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Balance
                                    </div>
                                    <div className="dashboard-card-text">
                                        KES {balance.toLocaleString()}
                                    </div>
                                </div>

                            </div>
                            {/* <TransactionList module="CRM" transactions={transactions} fetchTransactions={fetchTransactions}/> */}
                            <DealList 
                                module="CRM" 
                                deals={deals} 
                                fetchDeals={fetchDeals} 
                                handlePageChange={handlePageChange} 
                                page={page} 
                                pagecount={pagecount}
                            />

                            {/* <TransactionNew isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose} activeTabStatement={activeTabStatement}/> */}
                        </Tab>
                    </Tabs>     

                </section>

            </div>

        // </Layout>

    );
  };
  
  export default Lead;