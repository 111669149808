import { useState, useEffect, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

// import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'


//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import TransactionList from "../accounting/TransactionList";
import ItemEdit from "./ItemEdit";
import UserAlerts from "../../components/UserAlerts";


const Item = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const params = useParams()
    const navigate = useNavigate()
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    const [error, setError] = useState('');
    const errorRef = useRef(null);
    const sumQuantity = useRef(0);
    const sumInventoryValue = useRef(0);


    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    const itemId = params.id
    const { data: item } = useQuery({
        queryKey: ['item', itemId],
        queryFn: async () => {
        //   const response = await authAxios.get(`/inventory/items/${params.id}`);
          const response = await authAxios.get(`/inventory/items/${itemId}`);
          return response.data
        },
    });

    const { data: transactions } = useQuery({
        queryKey: ['inventory-ledger', page],
        queryFn: async () => {
          const response = await authAxios.get(`/accounting/inventory-ledger/?item=${params.id}&page=${page}`);
          setPageCount(response.data.page_count)
          calcStats(response.data.results)
          return response.data.results
        },
    });


    const handleFabClick = () => {
        navigate('/ratenew/',{state:{property:params.id}})
    }
    
    // let sumQuantity = 0
    // let sumInventoryValue = 0
       
    const calcStats = (txns) => {
        sumQuantity.current = txns.reduce((acc, txn) => {
            const qty = parseFloat(txn.quantity);
            if (txn.entry_type === 'D') {
                return acc + qty; 
            } else {
                return acc - qty; 
            }
          }, 0);

        sumInventoryValue.current = txns.reduce((acc, txn) => {
            const amt = parseFloat(txn.amount);
            if (txn.entry_type === 'D') {
                return acc + amt; 
            } else {
                return acc - amt; 
            }
          }, 0);
    }
    // calcStats()

    //Bootstrap Tabs
    const [key, setKey] = useState('profile');
    const [removeBorder, setRemoveBorder] = useState(true);

    const activeTabStatement = () => {
        setKey('statement')
    }

    // PAGINATION
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])


    return (
            <div className="dashboard-main">
                {item &&

                <section className="dashboard-content">
                    <div ref={errorRef}>
                        <UserAlerts error={error} />
                    </div>
                    <DashboardHeader dashboardTitle={item.name} dashboardPreTitle="Inventory" removeBorder={removeBorder} />

                    <Tabs
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="dashboard-tabs"
                        >
                        <Tab eventKey="profile" title="Profile">
                            <ItemEdit item={item} setError={setError}/>

                        </Tab>

                        <Tab eventKey="ledger" title="Inventory Ledger">
                            {clientProfile &&
                            <div className="dashboard-content-subheader">
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Quantity
                                    </div>
                                    {item &&
                                    <div className="dashboard-card-text">
                                        {sumQuantity.current.toLocaleString()}
                                    </div>
                                    }
                                    
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Value
                                    </div>
                                    <div className="dashboard-card-text">
                                        {clientProfile.currency_code} {sumInventoryValue.current.toLocaleString()}

                                    </div>
                                </div>

                            </div>
                            }
                            {/* {client && */}
                            <TransactionList 
                                module="INV" 
                                transactions={transactions} 
                                handlePageChange={handlePageChange} 
                                page={page} 
                                pagecount={pagecount}
                                // client={client}
                            />
                            {/* } */}
                        </Tab>
                    </Tabs>     

                </section>
                }

            </div>

    );
  };
  
  export default Item;