import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import authAxios from "./authAxios";

import { Link } from "react-router-dom";
import Pagination from '@mui/material/Pagination';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "./DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";

// import Layout from "./Layout";
import ThreeDotsMenu from "./ThreeDotsMenu";

function Suppliers() {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    const { data: suppliers, status, isPreviousData } = useQuery({
        queryKey: ['suppliers', page],
        queryFn: async () => {
          const response = await authAxios.get(`/purchase/suppliers/?page=${page}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
        keepPreviousData : true,
    });

    const handlePageChange = (event, value) => {
        setPage(value);
    };

     const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/dashboard/supplier/",

        }, 
    ];

    return (
            <div className="dashboard-main">

                <section className="dashboard-content">
                    
                    <DashboardHeader dashboardTitle="Suppliers" dashboardPreTitle="Purchase" />

                    {isMobile? 
                    <>
                    
                    {suppliers && suppliers.map(supplier => ( 

                    <Card className="dashboard-card" key={supplier.id}>
                        
                        <CardContent className="dashboard-card-content">
                            <div className="dashboard-card-title">{supplier.name}</div>
                            <div className="dashboard-card-subtitle">{supplier.telephone}</div>                            
                            <p>{supplier.address}</p>
                            <p>{supplier.email}</p>
                        </CardContent>
                    </Card>
                    ))}

                    </>

                    :
                    <>
                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>ID</th>
                                <th>Name</th>
                                {/* <th>Gender</th> */}
                                <th>Telephone</th>
                                <th>Email</th>
                                <th>Address</th>
                                <th></th>
                            </tr>
                        </thead>

                        {suppliers && suppliers.map(supplier => (

                            <tbody key={supplier.id}>
                                <tr>
                                    <td>{supplier.id}</td>
                                    <td>{supplier.name}</td>
                                    {/* <td>{customer.gender}</td> */}
                                    <td>{supplier.telephone}</td>
                                    <td>{supplier.email}</td>
                                    <td>{supplier.address}</td>

                                    {/* <td>
                                        <Pillchip label={room.room_status} />
                                    </td>
                                    <td>{Number(customer.price).toLocaleString('en-UK', { style: 'currency', currency: 'KES' })}</td> */}

                                    {/* <td className='styled-table-menu'><FontAwesomeIcon icon={faEllipsisVertical} /></td> */}
                                    <td className='styled-table-menu'>
                                        <ThreeDotsMenu data={supplier} threeSubMenu={threeSubMenu} />

                                    </td>
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>
                    </>

                    }

                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />

                    <Link to="/dashboard/suppliernew" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link>
                </section>
            </div>

    )
}
export default Suppliers