import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import './style.css';
import App from './App';

import axios from 'axios';
import ErrorBoundary from './errorBoundary';


const queryClient = new QueryClient()


// set axios global defaults
// import axios from './components/useAxios';
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;


const root = createRoot(document.getElementById("root"));

// const ErrorBoundary = ({ children }) => {
//   try {
//     return children;
//   } catch (error) {
//     return (
//       <div>
//         <h1>An error occurred</h1>
//         <p>{error.message}</p>
//       </div>
//     );
//   }
// };



root.render(
  // <React.StrictMode> //****DISABLED BECAUSE WAS DUPLICATING API CALLS IN AXIOS
  // <React.StrictMode> 
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <App />
      </QueryClientProvider>
  </BrowserRouter>
  // </React.StrictMode>
);



// const container = document.getElementById('root');
// const root = createRoot(container);
// root.render(<App tab="home" />);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
