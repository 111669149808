import React from "react";
import useAxios from "../../components/useAxios";
// import axios from "axios";
// import useAxiosInterceptor from '../components/useAxiosInterceptor';

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Radio, RadioGroup, FormControlLabel, Autocomplete, InputLabel, MenuItem } from "@mui/material";
import { FormControl } from "@mui/material";
import {IconButton, Button} from "@mui/material";

// import { Select } from "@mui/material";

import Select, { SelectChangeEvent } from '@mui/material/Select';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { faCircleMinus, faCirclePlus  } from '@fortawesome/free-solid-svg-icons';
import NumberFormat from "react-number-format";
import FormDrawer from "../../components/FormDrawer";


// import { Select } from "@mui/material";
// import { FormControl } from "@mui/material";
// import { MenuItem } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';

// IMAGE UPLOAD ANT DESIGN
import { message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

// import Sidebar from "./Sidebar";
import UserAlerts from "../../components/UserAlerts";
// import Layout from "./Layout";
import DashboardHeader from "../../components/DashboardHeader";
import Layout from "../../components/Layout";
import { TenMpOutlined } from "@mui/icons-material";



const OrderNew = ({isDrawerOpen, handleDrawerClose}) => {
    const {response, error, fetchData}  = useAxios();

    const { data: orderStatus } = useAxios({
        url: '/orders/order-status',
        method: 'get'
    })

    const [customers, setCustomers] = useState([]);
    const { data: customerData } = useAxios({
        url: '/crm/customers',
        // url: `/customers?all=true`,
        method: 'get'
    })


    const [products, setProducts] = useState([]);
    const { data: productData } = useAxios({
        // url: `/products?all=true`,
        url: "/products/products",
        method: 'get'
    })

    // console.log("CUSTOMERS", customers)

    const navigate = useNavigate();
    const [customer, setCustomer] = useState('');
    const [rate, setRate] = useState('');
    const [reference, setReference] = useState('');
    const [memo, setMemo] = useState('');
    const [status, setStatus] = useState('');
    const [price, setPrice] = useState();
    const [subTotal, setSubTotal] = useState(0);
    const [tax, setTax] = useState(0);
    const [total, setTotal] = useState(0);
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');
    const [customerError, setCustomerError] = useState(false)
    const [statusError, setStatusError] = useState(false)
    const [referenceError, setReferenceError] = useState(false)
    const [showTotals, setShowTotals] = useState(false);
    const [addRowsEnabled, setAddRowsEnabled] = useState(false);

    //DYNAMIC ORDER DETAILS TABLE
    const [rows, setRows] = useState([{}]);
    const columnsArray = ["quantity", "price"]; // pass columns here dynamically

    const handleAddRow = ({idx}) => {
        const item = {};
        setRows([...rows, item]);
        // console.log("ROWS",rows)
        // console.log("IDX",idx)
        setAddRowsEnabled(false);
    };

   
    const handleRemoveSpecificRow = (idx) => {
        const tempRows = [...rows]; // to avoid  direct state mutation
        const tempRows1 = [...rows]; // to avoid  direct state mutation

        console.log("BEFORE", tempRows1)
        console.log("BEFORE ROWS", rows)

        console.log("IDX", idx)

        tempRows.splice(idx, 1);



        setRows(tempRows);
        console.log("AFTER", tempRows)
        console.log("AFTER", rows)

        calcTotal()
    };

    const updateState = (e) => {
        // console.log("ROWS",rows)

        let prope = e.target.attributes.column.value; // the custom column attribute

        let index = e.target.attributes.index.value; // index of state array -rows
        let fieldValue = e.target.value; // value

        const tempRows = [...rows]; // avoid direct state mutation
        const tempObj = rows[index]; // copy state object at index to a temporary object
        tempObj[prope] = fieldValue; // modify temporary object

        if (prope = 'quantity' && price){
            // console.log("WE ARE HERE", price)
            // console.log(tempObj.price)
            tempObj.price =  price*fieldValue
        }

        // // return object to rows` clone
        tempRows[index] = tempObj;
        setRows(tempRows); // update state
    };


    const updateProduct = (prod, idx) =>{
        // const prodItem = {column:"Product", product:1};
        // console.log(prod.id)
        const product = products.find(product => product.id === prod.id);

        const tempRows = [...rows];
        tempRows[idx].product = prod.id
        if (!tempRows[idx].quantity){
            tempRows[idx].quantity = 1
        }
        tempRows[idx].id = idx
        tempRows[idx].price = product.price*tempRows[idx].quantity
        setPrice(product.price)
        // let t1 = subTotal + tempRows[idx].price
        // setSubTotal(t1)
        // let t2 = total + t1
        // setTotal(t2)

        // updateAddButton(idx)
        setRows(tempRows);
        setAddRowsEnabled(true);
        calcTotal()
    }

    // const updateQuantity = (e, idx) => {
    const updateQuantity = (e, idx, column, index) => {
        // let fieldValue = e.target.value; // value

        const tempRows = [...rows];
        tempRows[idx].quantity = e.target.value; 

        if (tempRows[idx].product){
            tempRows[idx].price = price*tempRows[idx].quantity
        }
        setRows(tempRows);
        calcTotal()
    }

    const updatePrice = (e, idx) => {
        const tempRows = [...rows];
        tempRows[idx].price = e.target.value; 
        setRows(tempRows);
        calcTotal()
    }

    const updateAddButton = (idx) => {
        const tempRows = [...rows];
        setAddRowsEnabled(true);
    }
    // console.log("ADD BTN",addRowsEnabled)

    const AddRowButton = ({addRowsEnabled}) => {
        const tempRows = [...rows];
        // console.log("ROW",tempRows[idx].id)
        // console.log("ADD ROW ENABLED",addRowsEnabled)
        // console.log("IDX",idx)
       
        if (addRowsEnabled){
        return(
            <div className="AddRow">
                <Button
                    id="AddRowButton"
                    onClick={handleAddRow} 
                    variant="outlined" 
                    size="small"
                    // startIcon={<AddCircleOutlineIcon className="dynamic-table-menu" />}
                    startIcon={<AddCircleOutlineIcon />}
                >
                     Product
                </Button>

            </div>

        )
        }
    }

    const calcTotal = () => {
        let t1 = 0
        let t2 = 0
        let t3 = 0

        const tempRows = [...rows];

        // console.log("ROWS",rows)
        // console.log("TMP ROWS",tempRows)

        // console.log("LENGTH", rows.length)
        for(let i= 0; i < rows.length; i++){
            // console.log(rows)
            // t1 = t1 + rows[i].price
            t1 = t1 + parseFloat(rows[i].price)
            // t1 = t1 + parseFloat(tempRows[i].price)


            // console.log("PRICE",rows[i].price)
            console.log("T1",t1)
        }
        // t2 = t2 + t1
        t3 = t1 + t2 + t3
        setSubTotal(t1)
        setTax(t2)
        setTotal(t3)

        if (t1>0){
            setShowTotals(true)
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        if (customer === '') {
            setCustomerError(true)
        }

        if (reference === '') {
            setReferenceError(true)
        }

       
        if (reference && customer) {

            //UPDATE ORDER DETAILS
            // postResults()
            const order_details = rows;
            console.log("POST",rows); // there you go, do as you please
            console.log("DETAILS",order_details); // there you go, do as you please

            // setOrderDetails(...rows);


            // const telephone = mobile.replace(/[()" "]/g,"")

            //Change the format of birth date before saving
            // const birth_date = moment(dt).format("YYYY-MM-DD")
            // const inventory_type = inventoryType
            // const product_type = productType

            //Change the format of Price field before saving
            let price = 0
            price = parseFloat(rate.replace(/,/g, ''));

            const sub_total = subTotal

            // const product = { client, name, sku, upc, cost, price, category, type, memo, user };
            const order = { client, reference, status, customer, sub_total, tax, total, memo, user, order_details };

            console.log(order)

            fetchData({
                url: '/orders/orders/',
                method: 'post',
                data: order,
              })

        } 
    }

    useEffect(() => {
        if (response.status === 201){
            // navigate('/orders/');
            navigate('/dashboard/orders/');

        }    
        if (productData.count){
            setProducts(Array.from(productData.results))
        }
        if (customerData.count){
            setCustomers(Array.from(customerData.results))
        }
    }, [ response.status, productData.count]); 


    return(

    // <div className="dashboard-container">
    // <Layout>
        


            <div className="dashboard-main">
            <section className="dashboard-content">


            <section className="dashboard-form">
                <UserAlerts error={error} />
                <DashboardHeader dashboardTitle="Add Order" dashboardPreTitle="Order Management" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <div className="grouped-fields-light">

                    <TextField className="custom-input"
                        onChange={(e) => {
                            setReferenceError(false)
                            setReference(e.target.value)
                        }}
                        label="Reference" 
                        variant="outlined" 
                        fullWidth
                        required
                        error={referenceError}
                    />

                    {customers && 
                    <Autocomplete
                        onChange={(event, newValue) => {
                            setCustomer(newValue.id);
                            console.log("VALUE", newValue.id)
                            }}
                        options={customers}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Customer"  /> }
                    />
                    } 

                    <FormControl 
                        variant="outlined" 
                        fullWidth className="custom-input" 
                        error={statusError}
                        required
                    >
                        <InputLabel>Status</InputLabel>
                        <Select
                            className="custom-select"
                            label="Status"
                            onChange={(e) => {
                                setStatusError(false)
                                setStatus(e.target.value)}
                            }
                            defaultValue = ""
                        >
                            {orderStatus && orderStatus.map((status) => { 
                                return(
                                <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>
                                )
                            })}   
                            
                        </Select>
                    </FormControl>
                    </div>
                    
                    {/* <table className="styled-table"> */}
                    {products &&

                    <table className="dynamic-table">
                    {/* <table > */}
                        <thead>
                            <tr>
                            <th className="text-center">#</th>
                            {/* {columnsArray.map((column, index) => (
                                <th className="text-center" key={index}>
                                {column}
                                </th>
                            ))}
                            <th /> */}
                            <th className="text-center">Product</th>
                            <th className="text-center">Quantity</th>
                            <th className="text-center">Price</th>

                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((item, idx) => (
                            <tr 
                                key={idx} id="activeRow"
                            >
                                <td>{idx + 1}</td>
                                <td>
                                    <div>
                                    <Autocomplete
                                        // onChange={(event, newValue) => {
                                        //     updateProduct(newValue.id, idx)
                                        //     }}
                                        onChange={(e, newValue) => updateProduct(newValue, idx)}  
                                        options={products}
                                        getOptionLabel={(option) => option.name}
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} //Disable label visibility

                                        // renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Customer"  /> }
                                        renderInput={(params) => <TextField {...params} label=" "  /> }
                                    />
                                
                                </div>
                                </td>


                                <td>
                                    <NumberFormat 
                                        className="td-textbox"
                                        // index={idx}
                                        // column={column}

                                        fullWidth
                                        // value={rows[idx][column]}
                                        value={rows[idx].quantity}

                                        variant="outlined" 
                                        InputLabelProps={{shrink: false}}
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} //Disable label visibility

                                        customInput={TextField}
                                        thousandSeparator={true} 
                                        required
                                        // error={rateError}
                                        // onChange={(e) => updateState(e)}  
                                        onChange={(e) => updateQuantity(e, idx)}  

                                    />
                                </td>

                                    <td>
                                    <NumberFormat 
                                        className="td-textbox"
                                        index={idx}
                                        fullWidth
                                        // value={rows[idx][column]}
                                        value={rows[idx].price}

                                        variant="outlined" 
                                        InputLabelProps={{shrink: false}}
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} //Disable label visibility

                                        customInput={TextField}
                                        thousandSeparator={true} 
                                        required
                                        // error={rateError}
                                        onChange={(e) => updatePrice(e, idx)}  
                                    />
                                    </td>

                                <td>
                                    {/* <button onClick={handleAddRow} className="btn btn-primary">
                                        Add Row
                                    </button> */}
                                    {/* <button type="button" className='dynamic-table-menu' onClick={() => handleRemoveSpecificRow(idx)}>
                                        <FontAwesomeIcon  icon={faCircleMinus}/>
                                    </button>
                                    <button type="button" className='dynamic-table-menu' onClick={handleAddRow}>
                                        <FontAwesomeIcon icon={faCirclePlus}/>
                                    </button> */}
                                    <IconButton 
                                        onClick={() => handleRemoveSpecificRow(idx)}
                                        aria-label="Delete" 
                                        className='dynamic-table-menu' 
                                        size="small">
                                        <RemoveCircleIcon fontSize="small" />
                                    </IconButton>

                                    {/* {addRowsEnabled &&
                                    <IconButton 
                                        onClick={handleAddRow} 
                                        // disabled = {addRowsEnabled}
                                        aria-label="Add" 
                                        className='dynamic-table-menu' 
                                        size="small">
                                        <AddCircleIcon fontSize="small" />
                                    </IconButton>
                                    } */}
                                    {/* <AddRowButton idx={idx} addRowsEnabled={addRowsEnabled} /> */}
                                </td>

                                {/* </>
                                ))}  */}


                            </tr>
                            ))}
                            {/* <tr>
                                <td colSpan={5} className="AddRow">
                                    <AddRowButton addRowsEnabled={addRowsEnabled} />
                                </td>
                            </tr> */}
                        </tbody>

                        

                    </table>
                    }


                    <div className="table-total">
                        <div className='table-card'>
                            <AddRowButton addRowsEnabled={addRowsEnabled} />
                        </div>

                        {/* {subTotal &&  */}

                        {showTotals && 
                        <div className='table-card'>
                            <div className="card-pretitle">
                                Subtotal: {subTotal.toLocaleString('en-UK', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                            </div>
                            <div className="card-pretitle">
                                Taxes: {tax.toLocaleString('en-UK', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                            </div>
                            <div className="card-text">
                                {/* {cntLeads} */}
                                {total.toLocaleString('en-UK', { style: 'currency', currency: 'KES' })}
                                {/* {total.toLocaleString('en-UK')} */}

                            </div>
                        </div>
                        }
                    </div>

                    <div className="grouped-fields-light">


                    <TextField className="custom-input"
                        onChange={(e) => {
                            setMemo(e.target.value)
                        }}
                        label="Notes"
                        variant="outlined"
                        multiline
                        rows={2}
                        fullWidth
                    />
                    </div>


                    {/* <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader custom-upload-card custom-input"
                        beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                        onChange={handleUpload}
                        maxCount={2}
                        >
                        {imageUrl ? (
                            <img
                            src={imageUrl}
                            alt="avatar"
                            style={{
                                width: '100%',
                            }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload> */}


                    {/* <div className="button-border-top">
                        <button onClick={postResults} className="btn btn-primary form-buttons">
                            Submit
                        </button>
                    </div> */}
                    <div className="button-border-top">
                        <button id="submit" className="btn btn-primary form-buttons" type="submit">
                            Save
                        </button>
                    </div>


                </form>

            </section>
            </section>

            </div>


        // </Layout>




    )

}

export default OrderNew;