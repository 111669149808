import React from "react"
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import useAxios from "../../components/useAxios";
import useAxiosInterceptor from "../../components/useAxiosInterceptor";

import Layout from "../../components/Layout";
import DashboardHeader from "../../components/DashboardHeader";
// import Pagination from './Pagination';
import Pagination from '@mui/material/Pagination';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Card, CardContent, TablePagination } from "@mui/material";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive'
// import FormDrawer from "../../components/FormDrawer";
// import TransactionNew from "../../components/TransactionNew";
// import CustomPagination from "../../components/CustomPagination";
// import AgentNew from "../../components/AgentNew";
// import LeadNew from "../../components/LeadNew";
import ThreeDotsMenu from "../../components/ThreeDotsMenu";


// const Transactions = ({ transactions, loading }) => {
const LeadList = ({module, leads, fetchLeads, handlePageChange, page, pagecount}) => {

    // console.log(activeTab)
    // if (loading) {
    //   return <h2>Loading...</h2>;
    // }
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    // const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const navigate = useNavigate()
    const params = useParams()

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true)
    };
  
    const handleDrawerClose = () => {
        setIsDrawerOpen(false)
    };

    const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            // url: "/lead/",
            url: "/dashboard/lead/",

        },
        
    ];

 
    useEffect(()=>{
        fetchLeads()
        // console.log("WE ARE HERE")
    },[isDrawerOpen])


    return (
        <>

            {isMobile? 
                    <>
                    
                    {leads && leads.map(lead => ( 

                    <Card className="dashboard-card" key={lead.id}>
                        
                        <CardContent className="dashboard-card-content">
                            <div className="dashboard-card-title">{lead.name}</div>
                            <div className="dashboard-card-subtitle">{lead.telephone}</div>                            
                            <p>Email: {lead.email}</p>
                            <p>Date: {lead.date}</p>
                            {/* <Pillchip label={booking.status_name} /> */}
                        </CardContent>
                        {/* <CardActions>
                            <Button size="small">Booking</Button>
                        </CardActions> */}
                    </Card>
                    ))}

                    </>

                    :
                    // If Device is not mobile, display data using a table
            
                    <table className="styled-table">

                        <thead>
                            <tr >
                                <th>ID</th>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Telephone</th>
                                <th>Email</th>
                                <th>Source</th>
                                <th></th>
                            </tr>
                        </thead>

                        {/* {console.log("T2",leads)} */}

                        {leads && leads.map(lead => (

                        <tbody key={lead.id}>
                            <tr>
                                <td>{lead.id}</td>
                                <td>{lead.date}</td>
                                <td>{lead.name}</td>
                                <td>{lead.telephone}</td>
                                <td>{lead.email}</td>
                                <td>{lead.source_name}</td>
                                {/* <td className='styled-table-menu'><FontAwesomeIcon icon={faEllipsisVertical} /></td> */}
                                <td className='styled-table-menu'>
                                    <ThreeDotsMenu data={lead} threeSubMenu={threeSubMenu} />
                                </td>
                            </tr>
                            
                        </tbody>
                        ))}           

                    </table>
            }
            
        {/* <LeadNew isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose} module={module}/> */}
        
        <Pagination 
            className="custom-mui-pagination"
            // color="primary" 
            count={pagecount} 
            page={page} 
            onChange={handlePageChange} 
        />

        {/* <Fab 
            className="fab" 
            color="primary" 
            aria-label="add"
            onClick={()=>{
                handleDrawerOpen()
            }
            }
        >
            <AddIcon />
        </Fab>  */}

        {/* navigate('/dashboard/invoicereport/',{state:{reference:reference}}); */}

        <Link to="/dashboard/contactnew" state={{ tag: "LEAD" }} className="nav-link">
            <Fab className="fab" color="primary" aria-label="add">
                <AddIcon />
            </Fab>
        </Link>

        </>

    );
  };
  
  export default LeadList;