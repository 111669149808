// import axios from "axios";
import authAxios from "./authAxios";
import useAxiosInterceptor from "./useAxiosInterceptor";
import { useState, useEffect } from "react";

const useAxios = (axiosParams) => {    
  const {accToken } = useAxiosInterceptor();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState('');

  // console.log("AXIOS PARAMS",axiosParams)


  //ADDEDD TO TEST/RESOLVE RACE CONDITION
  // const abortController = new AbortController();
  // const [abortController, setAbortController] = useState(null);


  const fetchData = async (params) => {
    // const fetchData = async ({url,method}) => {
    // async function fetchData (params) {
    // console.log("PARAMS",params)

    try {
      setLoading(true); //Added later for testing

      const response = await authAxios.request(params); //use Axios Instance
      // const response = await authAxios.request({ ...params, signal: abortController.signal }); //RACE CONDITIONS MODIFICATION
      setData (response.data);
      setResponse (response);


    } catch( error ) {
        console.log("USE AXIOS ERROR",error)
        if (error.response) {
          const status = error.response.status
          if (status === 400 | status === 401){
            setError(status +" " + error.response.statusText + " - " + error.response.request.response);
          }
          else if (status === 401){
            setError(status +" " + error.response.statusText + " - " + error.response.data.detail);
          }
        }
        else {
          setError( error.message);
        }

    } finally {
      setLoading(false);
    }


  };



  useEffect(() => {
    // const controller = new AbortController();
    // setAbortController(controller);

    fetchData(axiosParams);
    // fetchData({ ...axiosParams, signal: abortController.signal });

    // return () => {
    //   abortController.abort();
    //   console.log("ABORTING")

    // };
   

  }, [accToken]); // Enables proper refresh when the access token expires
  // }, [accToken]); // Enables proper refresh when the access token expires
  // }, []); // Enables proper refresh when the access token expires



    
  return { response, data, error, loading, fetchData} ;
  // return { response, data, error, loading, fetchData, abortController} ;

  

};

export default useAxios;


