import React from "react";
import { useState, useEffect, useRef } from "react";
import useAxios from "../../components/useAxios";
import { Link, useNavigate } from "react-router-dom";


import Pagination from '@mui/material/Pagination';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';


// import OrderNew from "./OrderNew";
// import ProductNew from "../../components/ProductNew";
// import useEbayToken from "../ebay/useEbayToken";


const Orders = () => {
    // const { accessToken, refreshEbayToken } = useEbayToken();

    const { data:orderData, fetchData:fetchOrderData}  = useAxios();   


    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    // FETCH ORDERS 
    // const { data: orderData, loading } = useAxios({
    //     url: `/orders/orders/?page=${page}`,
    //     method: 'get'
    // })
    const [orders, setOrders] = useState([]);
    const fetchOrders = () => {
        fetchOrderData({
            url: `/orders/orders/?page=${page}`,
            method: 'get',
          })
    }


    // FETCH CUSTOMERS 
    // const [employees, setEmployees] = useState([]);


    // const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    // const handleDrawerOpen = () => {
    //     setIsDrawerOpen(true)
    // };
    // const handleDrawerClose = () => {
    //     setIsDrawerOpen(false)
    // };

    //Pagination
     const handlePageChange = (event, value) => {
        setPage(value);
    };

     const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/customeredit/",
        }, 
    ];


    // FETCH CLIENT PROFILE
    // const clientID = localStorage.getItem('clientID');
    // const clientProfileID = localStorage.getItem('clientProfile');

    // const client = useRef("");
    // client.Id=localStorage.getItem('clientProfile');

    // const [ebayConfig, setEbayConfig] = useState([]);
    // const { data: ebayConfigData } = useAxios({
    //     url: `/ebay-config`,
    //     method: 'get'
    // })
    // console.log("EBAY",ebayConfigData)


    const [authorizationUrl, setAuthorizationUrl] = useState('');
    const navigate = useNavigate();
    const GetEbayOrders = () => {
        // const sourceURL = window.location.href;
        // const authUrl = `${ebayConfig.auth_url}?client_id=${ebayConfig.app_id}&response_type=code&scope=${ebayConfig.scope}&redirect_uri=${ebayConfig.redirect_uri}`;
        // window.location.replace(authUrl);
        // console.log("WE ARE HERE")
    }

    useEffect (()=>{
        fetchOrders()
    },[page]) //IMPORTANT: RUN API CALL ONLY ONCE!


    useEffect (() => {
        // console.log("EFFECT DATA", productData)
        if (orderData.page){
            setPageCount(orderData.page_count)
            setOrders(Array.from(orderData.results))
        }
        // if (ebayConfigData.length){
        //     setEbayConfig(ebayConfigData.find(ebayConfig => ebayConfig.client === parseInt(client.Id)))
        // }
    // },[page, orderData.count, isDrawerOpen])
    },[orderData])
    

    return (

            <div className="dashboard-main">

                <section className="dashboard-content">
                    
                    <DashboardHeader dashboardTitle="Sales Orders" dashboardPreTitle="Order Management" />

                    {isMobile? 
                    <>
                    
                    {orders && orders.map(order => ( 

                    <Card className="dashboard-card" key={order.id}>
                        
                        <CardContent className="dashboard-card-content">
                            <div className="dashboard-card-title">{order.customer_name}</div>
                            <div className="dashboard-card-subtitle">{order.reference}</div>                            
                            <p>{order.status_name}</p>
                            <p>{order.total}</p>
                            {/* <p>Gender: {customer.gender}</p> */}
                            {/* <Pillchip label={booking.status_name} /> */}
                        </CardContent>
                        {/* <CardActions>
                            <Button size="small">Booking</Button>
                        </CardActions> */}
                    </Card>
                    ))}

                    </>

                    :
                    // If Device is not mobile, display data using a table
                    <>
                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>Reference</th>
                                <th>Customer</th>
                                {/* <th>Gender</th> */}
                                <th>Date</th>
                                <th>Total</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>

                        {orders && orders.map(order => (

                            <tbody key={order.id}>
                                <tr>
                                    <td>{order.reference}</td>
                                    <td>{order.customer_name}</td>
                                    <td>{order.order_date}</td>
                                    <td>{Number(order.total).toLocaleString()}</td>
                                    <td>{order.status_name}</td>

                                    {/* <td>
                                        <Pillchip label={room.room_status} />
                                    </td>
                                    <td>{Number(customer.price).toLocaleString('en-UK', { style: 'currency', currency: 'KES' })}</td> */}

                                    <td className='styled-table-menu'><FontAwesomeIcon icon={faEllipsisVertical} /></td>

                                    {/* <td className='styled-table-menu'>
                                        <ThreeDotsMenu data={order} threeSubMenu={threeSubMenu} />
                                    </td> */}
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>
                    </>

                    }

                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />

                    {/* <Link to="/ordernew" className="nav-link"> */}
                    {/* <Link to="/getorders" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link> */}
                    
                    {/* <OrderNew isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose}/>


                    <Fab 
                        className="fab" 
                        color="primary" 
                        aria-label="add"
                        onClick={()=>{
                            handleDrawerOpen()
                        }
                        }
                    >
                        <AddIcon />
                    </Fab>  */}

                    {/* <Link to="/dashboard/ebay-accepted" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon onClick={GetEbayOrders}/>
                        </Fab>
                    </Link> */}

                    <Link to="/dashboard/ordernew" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link>

                </section>
            </div>

    )
}
export default Orders