import React, {memo} from "react";
import { Outlet } from "react-router-dom";

import Sidebar from "./Sidebar";
import { useMediaQuery } from 'react-responsive'
import { useCountRenders } from "../hooks/useCountRenders";
// import { Loading3QuartersOutlined } from "@ant-design/icons";
import ErrorBoundary from "../errorBoundary";
import { useLocation } from "react-router-dom";
// function Layout ({children}) {
// function Layout () {
// import ErrorBoundary from "../errorBoundary";


const Layout = () => {
    const location =  useLocation();
    // useCountRenders()
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })

    return(
        <div className="dashboard-container">
            {/* {isMobile? null: <Sidebar /> } */}
            {isMobile? null: 
            <Sidebar /> }

            {/* { children } */}
            <ErrorBoundary key={location.pathname}>
                <Outlet />
            </ErrorBoundary>
        </div>

    )
}

export default memo(Layout);