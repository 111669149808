import React from "react";
import axios from "axios";

import { useQueryClient, useMutation } from '@tanstack/react-query';

// import useAxios from "./useAxios";
import authAxios from "./authAxios";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Radio } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Autocomplete } from "@mui/material";

import moment from 'moment';

import UserAlerts from "./UserAlerts";
// import LayoutForm from "./LayoutForm";


const SupplierEdit = ({supplier, countries, setError}) => {
    const params = useParams()
    const [isUpdated, setIsUpdated] = useState(false)
    const queryClient = useQueryClient();


    // const {fetchData, data, response, error}  = useAxios();

    const [dt, setDt] = useState(new Date());

    const [name, setName] = useState(supplier.name);
    const [email, setEmail] = useState(supplier.email);
    const [mobile, setMobile] = useState(supplier.telephone);
    const [address, setAddress] = useState(supplier.address);
    const [id_type, setId_type] = useState('');
    const [id_number, setId_number] = useState('');
    const [gender, setGender] = useState('');
    const [country, setCountry] = useState('');
    const [website, setWebsite] = useState(supplier.website);

    const [isCompany, setIsCompany] = useState(false);
    const [isPerson, setIsPerson] = useState(false);

    const client = localStorage.getItem('clientID');
    const user = localStorage.getItem('userID');

    // const [supplier, setSupplier] = useState([]);
    const [contact, setContact] = useState("");

    const [nameError, setNameError] = useState(false)
    const [mobileError, setMobileError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [idNumberError, setIdNumberError] = useState(false)

    const navigate = useNavigate();

    const handleChange = (event) => {
        setName(event.target.value);
      };

    const handleIdNumber = (e) => {
        if (e.target.value.length>9){
            setIdNumberError(true)
        }
        else{
            setIdNumberError(false)
            setId_number(e.target.value)
        }
    }

    const mutation = useMutation({
        mutationFn: (supplier) => {
            return authAxios.put(`/purchase/suppliers/${params.id}/`, supplier);
        },
        onError: (error, variables, context) => {
            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['supplier', 'suppliers']);
            queryClient.refetchQueries(['supplier']);
            navigate('/dashboard/suppliers/');
        },
    })


    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setNameError(true)
        }

        if (mobile === '' ) {
            setMobileError(true)
        }

        if (email === '' ) {
            setEmailError(true)
        }

        if (name && mobile && email && idNumberError === false ) {
            const telephone = mobile.replace(/[()" "]/g,"")
            const birth_date = moment(dt).format("YYYY-MM-DD")

            // const supplier = { client, name, address, email, telephone, id_type, id_number, gender, birth_date, user };
            const supplier = { client, name, address, email, telephone, website, user };

            console.log(supplier)
            mutation.mutate(supplier)
        } 
    }


    return(
    // <Layout>

        <section className="dashboard-form">
            {supplier &&

            // {contact &&
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <div className="grouped-fields-light">
                    <TextField className="custom-input"
                        value={name}
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)
                            
                        }}
                        // onChange={handleChange}
                        label="Name" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        required
                        error={nameError}
                    />
                    <TextField className="custom-input"
                        value={email}
                        // value={supplier.email}

                        onChange={(e) => {
                            setEmailError(false)
                            setEmail(e.target.value)
                        }}
                        label="Email" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        required
                        error={emailError}
                    />

                    <NumberFormat 
                        value = {mobile}
                        // value = {supplier.telephone}
                        onChange={(e) => {
                            setMobileError(false)
                            setMobile(e.target.value)
                        }}
                        className="custom-input"
                        fullWidth
                        label="Mobile" 
                        variant="outlined" 
                        customInput={TextField}
                        format="### (###) #######" mask="_"
                        required
                        error={mobileError}
                    />

                    <TextField className="custom-input"
                        // value={address}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        label="Address" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={mobileError}
                    />

                    {/* <Autocomplete
                        // value={contact.country_name}
                        onChange={(event, newValue) => {
                            setCountry(newValue.id);
                            console.log("VALUE", newValue.id)
                            }}
                        options={countries}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Country"  /> }
                    /> */}

                    <TextField className="custom-input"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        label="Website" 
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={mobileError}
                    />  
                </div>
                {isPerson &&
                <>
                <div className="grouped-fields-light">
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        // defaultChecked=""
                        value={supplier.id_type}
                        onChange={(e) => setId_type(e.target.value)}
                        // className="custom-input"
                    >
                        <FormControlLabel className="custom-radio" value="10" control={<Radio />} label="Citizen ID" />
                        <FormControlLabel className="custom-radio" value="20" control={<Radio />} label="Passport" />
                    </RadioGroup>

                    <TextField className="custom-input"
                        value={supplier.id_number}
                        onChange={handleIdNumber}
                        label="ID/Passport Number" 
                        helperText="Maximum number of characters is 9" 
                        variant="outlined" 
                        fullWidth
                        required
                        error={idNumberError}
                    />
                </div>

                <div className="grouped-fields-light">
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={supplier.gender}
                        onChange={(e) => setGender(e.target.value)}
                    >
                        <FormControlLabel className="custom-radio" value="F" control={<Radio />} label="Female" />
                        <FormControlLabel className="custom-radio" value="M" control={<Radio />} label="Male" />
                    </RadioGroup>


                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                            label="Date of Birth"
                            value={supplier.birth_date}
                            onChange={(newDate) => {
                                setDt(moment(newDate).format("YYYY-MM-DD"));
                                }}
                            renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                </>
                }

                <div className="button-border-top">
                    <button id="submit" className="btn btn-primary form-buttons" type="submit">
                        Save
                    </button>
                </div>
            </form>
            }
        </section>
        // </Layout>

    )

}

export default SupplierEdit;
