// import React from "react";

import { useState, useEffect, useRef } from "react";
import authAxios from "../../components/authAxios";
import { useQueryClient, useMutation } from '@tanstack/react-query';


import { useNavigate, useParams } from "react-router-dom";
// import { InputLabel } from "@mui/material";
// import { FormControl } from "@mui/material";
// import { MenuItem } from "@mui/material";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";
// import { Select } from "@mui/material";
// import { Autocomplete } from "@mui/material";
import CustomSwitch from "../../components/CustomSwitch";


const ProductEdit = ({product, setError}) => {
    const params = useParams()
    const queryClient = useQueryClient();

    const client = localStorage.getItem('clientID');
    const user = localStorage.getItem('userID');
    const [name, setName] = useState(product.name);
    const [sku, setSku] = useState(product.sku);
    const [price, setPrice] = useState(product.price);
    const [memo, setMemo] = useState(product.memo);
    const [checked, setChecked] = useState(product.is_active);

    const [nameError, setNameError] = useState(false)

    const navigate = useNavigate();

    const mutation = useMutation({
        mutationFn: (product) => {
            return authAxios.put(`/products/skus/${params.id}/`, product);
        },
        onError: (error, variables, context) => {
            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['product', 'products']);
            navigate('/dashboard/products/');
        },
      })

    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setNameError(true)
        }

        if (name ) {
            const is_active = checked
            const product = { client, name, price, memo, is_active, user };
            // const product = { 
            //     client, name, upc, unit, cost, price, type, inventory_type, category, collection, memo, tax, 
            //     revenue_account, expense_account, inventory_account, user, product_options, has_variants
            // };
            console.log("PRODUCT", product)
            mutation.mutate(product)
        } 
    }


    return(
        <section className="dashboard-form">
           
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <div className="grouped-fields-light">

                    <TextField className="custom-input"
                        value={ sku || ''}
                        onChange={(e) => {
                            // setNameError(false)
                            setSku(e.target.value)
                        }}
                        label="SKU" 
                        // InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        required
                        // error={nameError}
                    />

                    <TextField className="custom-input"
                        value={ name || ''}
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)
                        }}
                        label="Name" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        required
                        error={nameError}
                    />
                    

                    <NumberFormat 
                        value = {price}
                        onChange={(e) => {
                            setPrice(e.target.value)
                        }}
                        className="custom-input"
                        fullWidth
                        label="Price" 
                        variant="outlined" 
                        customInput={TextField}
                        thousandSeparator
                        readOnly
                        required
                        // error={mobileError}
                    />

                    <TextField className="custom-input"
                        value={memo ?? ''}
                        onChange={(e) => setMemo(e.target.value)}
                        label="Memo"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        multiline
                        rows={3}
                        fullWidth
                    />
                </div>

                <div className="custom-switch-container custom-switch-container-white">
                        <CustomSwitch checked={checked} setChecked={setChecked}/>
                        <div className="custom-switch-label">
                            Active
                        </div>
                    </div>
                
                <div className="button-border-top">
                    <button id="submit" className="btn btn-primary form-buttons" type="submit">
                        Save
                    </button>
                </div>
            </form>
        </section>
    )

}

export default ProductEdit;